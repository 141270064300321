// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_menuWrapper__YOKiA {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: #00000033;
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);

}

.menu_menu__Bad4v {
    width: 50%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-right: 2px solid rgba(181, 112, 255, 0.5);
    backdrop-filter: blur(35.4px);
    -webkit-backdrop-filter: blur(35.4px);
    background: radial-gradient(162.72% 162.72% at 50.25% 50.25%, rgba(71, 17, 82, 0.3) 0%, rgba(0, 178, 255, 0.015) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.menu_menuItem__l8vky {
    box-sizing: border-box;
    color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    padding: 8px 16px;
    transition: 0.3s;
    cursor: pointer;
    text-decoration: unset;
}

.menu_menuItem__l8vky:hover {
    background-color: #FFFFFF1A;
}

.menu_disabled__qhGIJ {
    opacity: 0.5;
    cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu/menu.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,4BAA4B;IAC5B,oCAAoC;;AAExC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,gDAAgD;IAChD,6BAA6B;IAC7B,qCAAqC;IACrC,0NAA0N;AAC9N;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".menuWrapper {\n    display: flex;\n    justify-content: flex-start;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 10;\n    background: #00000033;\n    backdrop-filter: blur(9.2px);\n    -webkit-backdrop-filter: blur(9.2px);\n\n}\n\n.menu {\n    width: 50%;\n    height: 100%;\n    padding: 24px;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    border-right: 2px solid rgba(181, 112, 255, 0.5);\n    backdrop-filter: blur(35.4px);\n    -webkit-backdrop-filter: blur(35.4px);\n    background: radial-gradient(162.72% 162.72% at 50.25% 50.25%, rgba(71, 17, 82, 0.3) 0%, rgba(0, 178, 255, 0.015) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;\n}\n\n.menuItem {\n    box-sizing: border-box;\n    color: #FFFFFF;\n    border-radius: 8px;\n    width: 100%;\n    padding: 8px 16px;\n    transition: 0.3s;\n    cursor: pointer;\n    text-decoration: unset;\n}\n\n.menuItem:hover {\n    background-color: #FFFFFF1A;\n}\n\n.disabled {\n    opacity: 0.5;\n    cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuWrapper": `menu_menuWrapper__YOKiA`,
	"menu": `menu_menu__Bad4v`,
	"menuItem": `menu_menuItem__l8vky`,
	"disabled": `menu_disabled__qhGIJ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_searchWrapper__WBXt1 {
    width: 100%;
}

.search_inputWrapper__-tx6Z {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px 16px;
    background: radial-gradient(144.04% 144.04% at 50.25% 50.25%, rgba(213, 2, 255, 0.2) 0%, rgba(0, 178, 255, 0.01) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 1px solid #F5C5FF80;

}

svg {
    width: 24px;
    height: 24px;
}

.search_input__9AFuA {
    background: transparent;
    width: 100%;
    border: unset;
    color: #FFFFFF;

}

input::placeholder {
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 0.5;
}

.search_input__9AFuA:focus-visible {
    outline: unset;
}
`, "",{"version":3,"sources":["webpack://./src/components/Search/search.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,0NAA0N;IAC1N,2BAA2B;;AAE/B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,aAAa;IACb,cAAc;;AAElB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".searchWrapper {\n    width: 100%;\n}\n\n.inputWrapper {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    box-sizing: border-box;\n    border-radius: 5px;\n    padding: 8px 16px;\n    background: radial-gradient(144.04% 144.04% at 50.25% 50.25%, rgba(213, 2, 255, 0.2) 0%, rgba(0, 178, 255, 0.01) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;\n    border: 1px solid #F5C5FF80;\n\n}\n\nsvg {\n    width: 24px;\n    height: 24px;\n}\n\n.input {\n    background: transparent;\n    width: 100%;\n    border: unset;\n    color: #FFFFFF;\n\n}\n\ninput::placeholder {\n    font-size: 12px;\n    line-height: 14px;\n    color: #FFFFFF;\n    opacity: 0.5;\n}\n\n.input:focus-visible {\n    outline: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": `search_searchWrapper__WBXt1`,
	"inputWrapper": `search_inputWrapper__-tx6Z`,
	"input": `search_input__9AFuA`
};
export default ___CSS_LOADER_EXPORT___;

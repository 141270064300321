// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabNavigation_wrapper__BV9G4 {
    width: 100%;
}

.tabNavigation_bottomNavigation__8HnCp {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-around;
    width: 100%;
    padding: 7px 20px;
    background: radial-gradient(162.98% 162.72% at 50.25% 50.25%, rgb(38, 12, 64) 0%, rgb(15, 0, 32) 100%);
    border-radius: 10px;
}

.tabNavigation_navigationItem__5ep3o {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 25%;
}

.tabNavigation_navigationItem__5ep3o svg {
    width: 24px;
    height: 24px;
}
.tabNavigation_activeNavigationItem__c5A\\+D svg {
    width: 34px;
    height: 34px;
}

.tabNavigation_activeNavigationItem__c5A\\+D .tabNavigation_navigationTitle__BvvZb {
    background: linear-gradient(180.21deg, #FFFFFF 0.18%, #AC77D5 64.97%, #7014B7 185.28%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 15px;
}

.tabNavigation_navigationTitle__BvvZb {
    /*color: #FFFFFF;*/
    font-size: 11px;
    color: #30C9F2;
}

.tabNavigation_textGradient__n3s1A {

}
`, "",{"version":3,"sources":["webpack://./src/components/TabNavigation/tabNavigation.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,iBAAiB;IACjB,sGAAsG;IACtG,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sFAAsF;IACtF,6BAA6B;IAC7B,oCAAoC;IACpC,qBAAqB;IACrB,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB;;AAEA;;AAEA","sourcesContent":[".wrapper {\n    width: 100%;\n}\n\n.bottomNavigation {\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n    justify-content: space-around;\n    width: 100%;\n    padding: 7px 20px;\n    background: radial-gradient(162.98% 162.72% at 50.25% 50.25%, rgb(38, 12, 64) 0%, rgb(15, 0, 32) 100%);\n    border-radius: 10px;\n}\n\n.navigationItem {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n    width: 25%;\n}\n\n.navigationItem svg {\n    width: 24px;\n    height: 24px;\n}\n.activeNavigationItem svg {\n    width: 34px;\n    height: 34px;\n}\n\n.activeNavigationItem .navigationTitle {\n    background: linear-gradient(180.21deg, #FFFFFF 0.18%, #AC77D5 64.97%, #7014B7 185.28%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-clip: text;\n    text-fill-color: transparent;\n    font-size: 15px;\n}\n\n.navigationTitle {\n    /*color: #FFFFFF;*/\n    font-size: 11px;\n    color: #30C9F2;\n}\n\n.textGradient {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `tabNavigation_wrapper__BV9G4`,
	"bottomNavigation": `tabNavigation_bottomNavigation__8HnCp`,
	"navigationItem": `tabNavigation_navigationItem__5ep3o`,
	"activeNavigationItem": `tabNavigation_activeNavigationItem__c5A+D`,
	"navigationTitle": `tabNavigation_navigationTitle__BvvZb`,
	"textGradient": `tabNavigation_textGradient__n3s1A`
};
export default ___CSS_LOADER_EXPORT___;

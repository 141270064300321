// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright_pageWrapper__IdHM2 {
    width: 100%;
    height: 100%;
    min-width: calc(100vw - 40px);
    box-sizing: border-box;
    overflow-y: auto;
}

.copyright_title__xcwCs {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
}

.copyright_subtitle__59Idl {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.copyright_paragraph__NER4M {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/copyright/copyright.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".pageWrapper {\n    width: 100%;\n    height: 100%;\n    min-width: calc(100vw - 40px);\n    box-sizing: border-box;\n    overflow-y: auto;\n}\n\n.title {\n    font-size: 28px;\n    font-weight: 500;\n    line-height: 36px;\n    text-align: center;\n}\n\n.subtitle {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n}\n\n.paragraph {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    color: #FFFFFF;\n    opacity: 0.7;\n    margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": `copyright_pageWrapper__IdHM2`,
	"title": `copyright_title__xcwCs`,
	"subtitle": `copyright_subtitle__59Idl`,
	"paragraph": `copyright_paragraph__NER4M`
};
export default ___CSS_LOADER_EXPORT___;

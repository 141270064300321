import React, {FC, useEffect, useState} from 'react';
import "./userScore.css";
import { useTranslate } from '../../i18n';
import { useLeagues } from '../../context/LeaguesContext';

const UserScore: FC<{ score: string, lang: string, isPlaying: boolean, onTabChange: (value: number) => void }> = ({ score = "0.00", lang, isPlaying = false, onTabChange }) => {
    const translate = useTranslate();
    const { usersLeague } = useLeagues();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying) {
                setProgress(prev => (prev >= 100 ? 0 : prev + (100 / (60 * 1000 / 5))));
            } else {
                setProgress(0)
            }
        }, 5);

        return () => clearInterval(interval);
    }, [isPlaying]);

    return (
        <div className={'progressWrapper'} style={{background: `conic-gradient(#854AFF ${progress}%, transparent 0%)`}}>
            <div className={'bWrapper'}>
                <div className={"balanceWrapper"}>
                <p onClick={() => onTabChange(7)} className={"leagueName"}>{usersLeague?.name}</p>
                    <p className={"balanceTitle"}>{translate.balance_title}</p>
                    <h2 className={"balanceScore"}>{score}</h2>
                    <h3 className={"balanceSubtitle"}>{translate.balance_subtitle}</h3>
                </div>
            </div>
        </div>
    );
};

export default UserScore;

import {axiosInstance} from "../axiosConfig/axiosConfig";

export interface StationInterface {
    "id": number,
    "name": string,
    "description": string,
    "url": string,
    "price_per_minute": string,
    genres: any,
    country: any,
}

export async function getStations(country_code: string): Promise<StationInterface[]> {
    const response = await axiosInstance.get(
        `/stations`,
        {
            params: { country_code }
        }
    );
    return response.data;
}

export async function getGenres(): Promise<any[]> {
    const response = await axiosInstance.get(
        `/genres`,
    );
    return response.data;
}

export async function getCountries(): Promise<any[]> {
    const response = await axiosInstance.get(
        `/countries`,
    );
    return response.data;
}

export async function getStationById(id: string,  telegram_id: string): Promise<StationInterface> {
    const response = await axiosInstance.get(
        `/stations/${id}`,
        {
            params: { id, telegram_id },

        }
    );
    return response.data;
}
import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { ReactComponent as ShareIcon } from "../../assets/icons/player/shareIcon.svg";
import { ReactComponent as PlayArrowIcon } from "../../assets/icons/player/playIcon.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/player/pauseIcon.svg";
import { ReactComponent as SkipNextIcon } from "../../assets/icons/player/nextPlayIcon.svg";
import { ReactComponent as SkipPreviousIcon } from "../../assets/icons/player/prevPlayIcon.svg";

import UserScore from "../UserScore/UserScore";
import VolumeControl from "../VolumeControl/VolumeControl";
import { useUser } from "../../context/UserContext";
import { useStations } from "../../context/StationsContext";
import { useFavorites } from "../../context/FavoritesContext";
import { getAllFavoritesByTelegramId } from "../../service/favorites/favorites";
import { ReactComponent as FavoriteActiveIcon } from "../../assets/icons/favoriteActiveIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favoriteIcon.svg";
import { BASE_ORIGIN } from "../../service/axiosConfig/axiosConfig";

import styles from "./styles.module.css";
import { usePlayer } from "../../context/PlayerContext";

const PlayerContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "max-content",
  color: "#FFFFFF",
  padding: "0 20px 20px",
  borderRadius: "10px",
}));

const ControlsContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "46px",
}));

const StationName = styled(Typography)(() => ({
  fontSize: "21px",
  fontWeight: "400",
  fontFamily: "Abel",
  color: "#30C9F2",
  textAlign: "center",
  width: "100%",
}));

const PlayerControls: React.FC<{ lang: "en" | "ru", onTabChange: (value: number) => void }> = ({ lang, onTabChange }) => {
  const { user, setUser } = useUser();
  const { station, stations, handleSelectStation } = useStations();
  const { favorites, handleToggleFavorite, handleGetFavoritesByUser } =
    useFavorites();

  const [volume, setVolume] = useState(50);
  const { isPlaying, setIsPlaying, handlePlayPause } = usePlayer();
  const [score, setScore] = useState<number>(0);
  const [updatedScore, setUpdatedScore] = useState(0);
  const [progress, setProgress] = useState(0);
  const pointsAccumulated = useRef(0);

  const handleVolumeChange = (newVolume: number) => {
    setVolume(newVolume);
  };

  const handlePrevious = (id: number) => {
    const currentIndex = stations.findIndex((station) => station.id === id);
    const previousIndex =
      (currentIndex - 1 + stations.length) % stations.length;
    handleSelectStation(stations[previousIndex].id.toString(), lang);
    setIsPlaying(true);
  };

  const handleNext = (id: number) => {
    const currentIndex = stations.findIndex((station) => station.id === id);
    const nextIndex = (currentIndex + 1) % stations.length;
    handleSelectStation(stations[nextIndex].id.toString(), lang);
    setIsPlaying(true);
  };

  const handleProgress = (state: { playedSeconds: number }) => {
    setProgress(state.playedSeconds);
    if (station) {
      const pointsPerSecond = parseFloat(station.price_per_minute) / 60;
      pointsAccumulated.current += pointsPerSecond;
      const newScore =
        parseFloat(user?.wallet?.balance) + pointsAccumulated.current;
      setScore(newScore);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const telegramId = urlParams.get("telegram_id");

    if (telegramId) {
      const ws = new WebSocket(`wss://${BASE_ORIGIN}/ws/score/${telegramId}/`);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.balance !== undefined) {
          setUpdatedScore(data.balance);
          if (user) {
            setUser((prev: any) => ({
              ...prev,
              wallet: { ...user.wallet, balance: data.balance },
            }));
          }
        }
      };

      ws.onclose = () => {};

      ws.onerror = (error) => {
        console.error(error);
      };

      const intervalId = setInterval(() => {
        if (pointsAccumulated.current > 0 && isPlaying) {
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ points: 1 * user.boost_multiplier }));
                pointsAccumulated.current = 0;
            } else {
                console.warn('WebSocket connection is not open. Retrying...');
            }
        }
    }, 60000);

    return () => {
        clearInterval(intervalId);
    };
    }
  }, [isPlaying]);

  useEffect(() => {
    if (station) {
      // setIsPlaying(true);
    } else if (stations && !!stations.length) {
      handleSelectStation(stations[0]?.id.toString(), lang);
    }
  }, [station, stations]);

  useEffect(() => {
    if (user) {
      handleGetFavoritesByUser(user.telegram_id);
    }
  }, [user]);

  return (
    <PlayerContainer>
      <UserScore
        isPlaying={isPlaying && !!station && !!station.id}
        lang={lang}
        onTabChange={onTabChange}
        score={!!updatedScore ? updatedScore.toFixed(0) : score.toFixed(0)}
      />
      <div className={styles.infoWrapper}>
        <div
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              await handleToggleFavorite(
                station?.id.toString(),
                user?.telegram_id
              );
              await getAllFavoritesByTelegramId(user?.telegram_id);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {Array.isArray(favorites) &&
          favorites.find((fav) => fav?.id === station?.id) ? (
            <FavoriteActiveIcon className={styles.icon} />
          ) : (
            <FavoriteIcon className={styles.icon} />
          )}
        </div>
        <StationName>{station?.name}</StationName>
        <ShareIcon className={styles.icon} />
      </div>
      <ControlsContainer>
        <IconButton
          className={styles.controlIcon}
          onClick={() => handlePrevious(station.id)}
          aria-label="previous"
        >
          <SkipPreviousIcon
            style={{
              fill: "#ffffff",
              width: "36px",
              height: "36px",
              marginLeft: "2px",
            }}
          />
        </IconButton>
        <IconButton
          className={styles.playIcon}
          onClick={handlePlayPause}
          aria-label="play/pause"
        >
          {isPlaying ? (
            <PauseIcon
              style={{ fill: "#ffffff", width: "36px", height: "36px" }}
            />
          ) : (
            <PlayArrowIcon
              style={{
                fill: "#ffffff",
                width: "36px",
                height: "36px",
                marginLeft: "4px",
              }}
            />
          )}
        </IconButton>
        <IconButton
          className={styles.controlIcon}
          onClick={() => handleNext(station.id)}
          aria-label="next"
        >
          <SkipNextIcon
            style={{
              fill: "#ffffff",
              width: "36px",
              height: "36px",
              marginRight: "4px",
            }}
          />
        </IconButton>
      </ControlsContainer>
      <div style={{ display: "none" }}>
        <ReactPlayer
          url={station?.url}
          playing={isPlaying}
          controls={false}
          playsinline
          volume={volume / 100}
          width="100%"
          height="50px"
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
          onProgress={handleProgress}
        />
      </div>

      <VolumeControl
        volume={volume}
        onVolumeChange={handleVolumeChange}
      />
    </PlayerContainer>
  );
};

export default PlayerControls;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__lOwdN {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 23px 0;
    width: 100%;
}

.header_header__lOwdN svg {
    width: 40px!important;
    height: 40px!important;

    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;;IAEtB,eAAe;AACnB","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 23px 0;\n    width: 100%;\n}\n\n.header svg {\n    width: 40px!important;\n    height: 40px!important;\n\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__lOwdN`
};
export default ___CSS_LOADER_EXPORT___;

import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactNode,
} from 'react';

import {getUser, startSession, UserInterface} from "../service/users/users";

interface UserContextType {
    user: UserInterface;
    setUser:  React.Dispatch<any>;
    handleGetUserByTelegramId: (id: string) => Promise<void>;
    handleStartSession: (stationId: string, telegramId: string) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<any>(null);

    const urlParams = new URLSearchParams(window.location.search);
    const telegramId = urlParams.get('telegram_id') || "391412971";

    const handleGetUserByTelegramId = async (id: string): Promise<void> => {
        try {
            const user = await getUser(id as string);

            if (user) {
                setUser(user);
            } else {
                console.error('Failed to fetch User');
            }
        } catch (error) {
            console.error('Failed to fetch User', error);
        }
    };

    const handleStartSession = async (telegramId: string) => {
        try {
            await startSession({ telegram_id: telegramId, session_id: "1448", device: "iPhone" });
        } catch (error) {
            console.error('Failed to update user', error);
        }
    };

    useEffect(() => {
        if (telegramId) {
            try {
                handleGetUserByTelegramId(telegramId);
            } catch (error) {
                console.error('Failed to fetch user', error);
            }
        }

    }, [telegramId]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                handleGetUserByTelegramId,
                handleStartSession,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within an UserProvider');
    }
    return context;
};

import React, {createContext, useState, useContext, ReactNode} from 'react';

interface GameState {
    score: number;
    gameStatus: 'loading' | 'playing' | 'ended';
}

const GameContext = createContext<any>(null);

export const GameProvider = ({ children }: { children: ReactNode }) => {
    const [score, setScore] = useState(0);
    const [gameStatus, setGameStatus] = useState<'loading' | 'playing' | 'ended'>('loading');

    const startGame = () => setGameStatus('playing');
    const endGame = () => setGameStatus('ended');
    const resetGame = () => {
        setGameStatus('loading');
        setScore(0);
    };

    return (
        <GameContext.Provider value={{ score, setScore, gameStatus, startGame, endGame, resetGame }}>
            {children}
        </GameContext.Provider>
    );
};

export const useGameContext = () => useContext(GameContext);

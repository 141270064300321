// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/onboadringBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComingSoon_onboardingWrapper__YSFVc {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: max-content;
  min-height: 100%;
  min-width: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  z-index: 10001;
}

.ComingSoon_closeIcon__JafEr {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
}

.ComingSoon_contentWrapper__K966F {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  height: 100vh; 
  padding: 16px;
}

.ComingSoon_title__lgUiS {
  display: block;
  font-size: 32px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;

}
`, "",{"version":3,"sources":["webpack://./src/components/ComingSoon/ComingSoon.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,MAAM;EACN,SAAS;EACT,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,yDAAkE;EAClE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;;AAEX","sourcesContent":[".onboardingWrapper {\n  position: absolute;\n  right: 0;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  height: 100%;\n  width: max-content;\n  min-height: 100%;\n  min-width: 100%;\n  background-image: url(\"../../assets/img/onboadringBackground.png\");\n  background-size: cover;\n  z-index: 10001;\n}\n\n.closeIcon {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  width: 40px;\n  height: 40px;\n}\n\n.contentWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center; \n  height: 100vh; \n  padding: 16px;\n}\n\n.title {\n  display: block;\n  font-size: 32px;\n  font-weight: 400;\n  line-height: 30px;\n  margin: 0;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onboardingWrapper": `ComingSoon_onboardingWrapper__YSFVc`,
	"closeIcon": `ComingSoon_closeIcon__JafEr`,
	"contentWrapper": `ComingSoon_contentWrapper__K966F`,
	"title": `ComingSoon_title__lgUiS`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button_wrapper__T-H2X {
  position: relative;
  display: block;
  padding: 6px 27px;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  background: radial-gradient(50% 50%, #6d1d86 0%, #0b0433 100%);
 
  transition: all 0.3s ease;
  margin: auto;
  z-index:  1;
}
.Button_button_wrapper__T-H2X::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  padding: 1px;
  background: linear-gradient(90deg, #B570FF 100%, #F5C5FF 100%, #6471 100%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  z-index: -1;
}

.Button_whiteText__smCpk {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(180deg, #fff 0%, #d502ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Button_redText__kAIdp {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #ff0004;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,8DAA8D;;EAE9D,yBAAyB;EACzB,YAAY;EACZ,WAAW;AACb;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,YAAY;EACZ,0EAA0E;EAE1E,sEAAsE;EACtE,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,0DAA0D;EAC1D,qBAAqB;EACrB,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".button_wrapper {\n  position: relative;\n  display: block;\n  padding: 6px 27px;\n  border-radius: 5px;\n  text-align: center;\n  text-transform: uppercase;\n  background: radial-gradient(50% 50%, #6d1d86 0%, #0b0433 100%);\n \n  transition: all 0.3s ease;\n  margin: auto;\n  z-index:  1;\n}\n.button_wrapper::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  border-radius: 5px;\n  padding: 1px;\n  background: linear-gradient(90deg, #B570FF 100%, #F5C5FF 100%, #6471 100%);\n  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);\n  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);\n  -webkit-mask-composite: xor;\n  z-index: -1;\n}\n\n.whiteText {\n  font-family: var(--font-family);\n  font-weight: 400;\n  font-size: 12px;\n  text-align: center;\n  background: linear-gradient(180deg, #fff 0%, #d502ff 100%);\n  background-clip: text;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.redText {\n  font-family: var(--font-family);\n  font-weight: 400;\n  font-size: 12px;\n  text-align: center;\n  color: #ff0004;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_wrapper": `Button_button_wrapper__T-H2X`,
	"whiteText": `Button_whiteText__smCpk`,
	"redText": `Button_redText__kAIdp`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, useState } from "react";

import { ReactComponent as MenuIcon } from "../../assets/icons/header/menuIcon.svg";
import { ReactComponent as StarsIcon } from "../../assets/icons/header/xIcon.svg";

import styles from "./header.module.css";
import { ComingSoon } from "../ComingSoon/ComingSoon";

interface HeaderProps {
  onMenuOpen: () => void;
}

const Header: FC<HeaderProps> = ({ onMenuOpen }) => {
  const [isOpenSoonPage, setIsOpenSoonPage] = useState(false);

  const toggleSoonPage = () => {
    setIsOpenSoonPage(!isOpenSoonPage);
  };

  return (
    <>
      {isOpenSoonPage ? (
        <ComingSoon onClose={() => toggleSoonPage()} />
      ) : (
        <div className={styles.header}>
          <MenuIcon onClick={onMenuOpen} />

          <StarsIcon onClick={() => toggleSoonPage()} />
        </div>
      )}
    </>
  );
};

export default Header;

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser } from "./UserContext";
import {
  getLeaderBoard,
  getLeague,
  getLeagues,
  getUserLeagueTopUsers,
  LeaderboardResponse,
  LeagueInterface,
  updateLeague,
} from "../service/leagues/leagues";

interface LeagueContextType {
  league: LeagueInterface | null;
  usersLeague: any | null;
  totalLeagues: any[];
  leagueTopUsers: any[];
  selectedLeague: any | null;
  passedLeagues: any[];
  handleLeagueUpgrade: (id: number, leagueId: number) => void;
  handleGetUserLeagues: (telegramId: string) => Promise<void>;
  handleGetLeagueTopUsers: (telegramId: string) => Promise<void>;
  handleLeagueSwipe: (leagueId: number) => Promise<void>;
  handleGetLeaderBoard: (
    telegramId: string
  ) => Promise<LeaderboardResponse[] | undefined>;
  leaderBoard: LeaderboardResponse[];
  userCard: LeaderboardResponse | null;
}

const LeaguesContext = createContext<LeagueContextType | undefined>(undefined);

export const LeaguesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const [usersLeague, setUsersLeague] = useState<any | null>(null);
  const [league, setLeague] = useState<any | null>(null);
  const [totalLeagues, setTotalLeagues] = useState<any[]>([]);
  const [leaderBoard, setLeaderBoard] = useState<any[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<any | null>(null);
  const [passedLeagues, setPassedLeagues] = useState<any[]>([]);

  const [leagueTopUsers, setLeagueTopUsers] = useState<any[]>([]);
  const [userCard, setUserCard] = useState<LeaderboardResponse | null>(null);

  const handleLeagueSwipe = async (id: number) => {
    setSelectedLeague(totalLeagues.find((item) => item.id === id));
    setLeaderBoard([]);

    const leagueData = await getLeague(id);

    const usersWithRank = leagueData?.top_users?.map(
      (item: any, index: number) => ({
        ...item,
        rank: index + 1,
        wallet: item.wallet_balance,
      })
    );

    setLeaderBoard(usersWithRank);
  };

  const handleGetUserLeagues = async () => {
    if (!user?.telegram_id) return;

    try {
      const response = await getLeagues(user.telegram_id);
      setLeague(response.current_league);
      setUsersLeague(response.current_league);
      setSelectedLeague(response.current_league);
      setPassedLeagues(response.passed_leagues);
      const updatedArray = response.passed_leagues
        ? [
          ...response.passed_leagues.sort((a,b) => a.id - b.id),
            response.current_league,
            ...response.upcoming_leagues,
          ]
        : [response.current_league, ...response.upcoming_leagues];
      setTotalLeagues(updatedArray);

      const leagueData = await getLeague(response.current_league.id);

      const usersWithRank = leagueData?.top_users?.map(
        (item: any, index: number) => ({
          ...item,
          rank: index + 1,
          wallet: item.wallet_balance,
        })
      );

      setLeaderBoard(usersWithRank);

      const foundUser = usersWithRank?.find(
        (item: any) => item.telegram_id === user.telegram_id
      );

      if (foundUser) {
        setUserCard(foundUser);
      }
    } catch (error) {
      console.log("Failed to fetch Leagues");
    }
  };

  const handleLeagueUpgrade = async (id: number, leagueId: number) => {
    const response = await updateLeague(id, leagueId);

    await handleGetUserLeagues();

    console.log(response);
  };

  const handleGetLeagueTopUsers = async () => {
    if (!user?.telegram_id) return;
    try {
      const data = await getUserLeagueTopUsers(user.telegram_id);

      console.log(data);

      if (data) {
        setLeagueTopUsers(
          data?.topUsers?.map((item: any, index: number) => {
            return { ...item, rank: index + 1 };
          })
        );
        const foundUser = data?.topUsers?.find(
          (item: any) => item.telegram_id === user.telegram_id
        );

        if (foundUser) {
          setUserCard({
            ...foundUser,
            rank: data.user_rank,
            wallet: data.user_balance,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const handleGetLeaderBoard = async () => {
    if (!user?.telegram_id) return;
    try {
      const response = await getLeaderBoard(user.telegram_id);
      setLeaderBoard(response);
      const foundUser = response.find(
        (item) => item.telegram_id === user.telegram_id
      );

      if (foundUser) {
        setUserCard(foundUser);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (user?.telegram_id) {
      handleGetUserLeagues();
      // handleGetLeaderBoard();
    }
  }, [user?.telegram_id]);

  return (
    <LeaguesContext.Provider
      value={{
        league,
        usersLeague,
        leagueTopUsers,
        totalLeagues,
        selectedLeague,
        passedLeagues,
        handleLeagueSwipe,
        handleLeagueUpgrade,
        handleGetUserLeagues,
        handleGetLeaderBoard,
        handleGetLeagueTopUsers,
        leaderBoard,
        userCard,
      }}
    >
      {children}
    </LeaguesContext.Provider>
  );
};

export const useLeagues = (): LeagueContextType => {
  const context = useContext(LeaguesContext);
  if (context === undefined) {
    throw new Error("useLeagues must be used within an LeaguesProvider");
  }
  return context;
};

import {axiosInstance} from "../axiosConfig/axiosConfig";
import {StationInterface} from "../stations/stations";
import {BalanceResponse} from "../wallets/wallets";

export interface UserInterface {
    "id": number;
    "telegram_id": number;
    "first_name": string;
    "last_name": string;
    "username": string;
    "photo_url": string;
    "auth_date": Date;
    "session_id": string;
    "device": string;
    "favorite_stations": StationInterface[];
    "wallet": BalanceResponse;
    "boost_multiplier": number;
    referrals: any;
    is_terms_accepted: boolean;
}

export interface StartSessionDTO {
    device: string;
    telegram_id: string;
    session_id: string
}

export async function getUser(id: string): Promise<UserInterface> {
    const response = await axiosInstance.get(
        `/api/user/${id}`,
    );
    return response.data;
}

export async function startSession(data: StartSessionDTO) {
    const response = await axiosInstance.post(
        `/api/users/`,
        data
    );
    return response.data;
}
import React, { FC, useEffect, useState } from "react";
import ReferralLink from "../../components/ReferralLink/ReferralLink";
import styles from "./refLink.module.css";
import { translate } from "../../i18n";
import { useUser } from "../../context/UserContext";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import { axiosInstance } from "../../service/axiosConfig/axiosConfig";
import TaskModal from "../../components/Task/TaskModal/TaskModal";

const Tasks: FC<{ lang: "en" | "ru" }> = ({ lang }) => {
  const { user, handleGetUserByTelegramId } = useUser();

  const [tasks, setTasks] = useState<any[]>([]);
  const [completed, setCompleted] = useState<any[]>([]);

  const [selectedTask, setSelectedTask] = useState<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (task: any) => {
    setSelectedTask(task);
    setOpen(true);
  };
  const handleClose = async () => {
    setSelectedTask(null);
    setOpen(false);
    await fetchTasks(user?.telegram_id);
  };

  const fetchTasks = async (id: number) => {
    const data = await axiosInstance
      .get(`api/active-tasks/?telegram_id=${id}`)
      .then((res) => res.data);

    if (data) {
      console.log(data);
      // @ts-ignore
      setTasks([
        ...data.active_subscribe_tasks.map((task: any) => ({
          ...task,
          type: "subscribe",
          link: task.channel_link,
        })),
        ...data.active_social_tasks.map((task: any) => ({
          ...task,
          type: "social",
          link: task.social_link,
        })),
      ]);
      // @ts-ignore
      setCompleted([
        ...data.completed_subscribe_tasks,
        ...data.completed_social_tasks,
      ]);
    }
  };

  useEffect(() => {
    if (user?.telegram_id) {
      fetchTasks(user?.telegram_id);
    }
  }, [user]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const telegramId = urlParams.get("telegram_id");
    if (telegramId) {
      handleGetUserByTelegramId(telegramId as string);
    }
  }, []);

  return (
    <div className={styles.pageWrapper}>
      {open && (
        <TaskModal
          id={selectedTask.id}
          title={lang === "en" ? selectedTask.name_en : selectedTask.name_ru}
          description={
            lang === "en"
              ? selectedTask.description_en
              : selectedTask.description_ru
          }
          points={selectedTask.points}
          type={selectedTask.type}
          icon={selectedTask.icon}
          link={selectedTask.link}
          onClose={handleClose}
        />
      )}
      <h1 className={styles.pageTitle}>Tasks</h1>
      <p className={styles.pageSubtitle}>Complete tasks and</p>
      <p className={styles.pageSubtitle}>get IMPULSE</p>

      <div className={styles.refListWrapper}>
        <ul className={styles.list}>
          {Array.isArray(tasks) &&
            !!tasks.length &&
            tasks.map((task: any) => (
              <div
                onClick={() => handleOpen(task)}
                key={task.id + task.type}
                className={styles.refItem}
              >
                <div
                  style={{
                    background: `url('${task.icon}')`,
                    backgroundSize: "contain",
                  }}
                  className={styles.avatar}
                ></div>
                <div className={styles.info}>
                  <div className={styles.refName}>
                    <p className={styles.name}>
                      {lang === "en" ? task?.name_en : task?.name_ru}
                    </p>
                    <p className={styles.username}>
                      {lang === "en"
                        ? task?.description_en
                        : task?.description_ru}
                    </p>
                  </div>
                  <div className={styles.reward}>
                    <p className={styles.rewardTopText}>Reward</p>
                    <p className={styles.rewardText}>{task?.points}</p>
                  </div>
                </div>
              </div>
            ))}
          {Array.isArray(completed) &&
            !!completed.length &&
            completed.map((task: any) => (
              <div
                key={task.id + task.type}
                className={styles.refItem}
              >
                <div
                  style={{
                    background: `url('${task.icon}')`,
                    backgroundSize: "contain",
                  }}
                  className={styles.avatar}
                ></div>
                <div className={styles.info}>
                  <div className={styles.refName}>
                    <p className={styles.name}>
                      {lang === "en" ? task?.name_en : task?.name_ru}
                    </p>
                    <p className={styles.username}>
                      {lang === "en"
                        ? task?.description_en
                        : task?.description_ru}
                    </p>
                  </div>
                  <div className={styles.rewardDone}>
                    <p className={styles.done}>DONE</p>
                  </div>
                </div>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Tasks;

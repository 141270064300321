import { FC, useEffect, useState } from 'react';
import styles from "./refLink.module.css";
import { translate, useTranslate } from '../../i18n';
import { useUser } from "../../context/UserContext";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import MockImg from '../../assets/img/onboarding1.png'
import { useUtils } from '@telegram-apps/sdk-react';
import { axiosInstance } from '../../service/axiosConfig/axiosConfig';

const RefLink: FC<{ lang: "en" | "ru" }> = ({ lang }) => {
  const { user } = useUser();
  const utils = useUtils();
  const translate = useTranslate()

  const [friends, setFriends] = useState([]);

  const fetchFriends = async () => {
    const data = await axiosInstance.get(`/api/friends/?telegram_id=${user?.telegram_id as number}`).then(res => res.data);
    setFriends(data)
  }

    const referralLink = `https://t.me/RadiogrammBot?start=${user?.telegram_id}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
        }, () => {
            console.error("Failed to copy the text to clipboard.");
        });
    };

    useEffect(() => {
        if (user && !friends.length) {
            fetchFriends();
        }
    }, [user]);
  
  const text = translate.invite_text

    return (
        <div className={styles.pageWrapper}>
        <h1 className={styles.pageTitle}>{translate.friends}</h1>
        <p className={styles.pageSubtitle}>{translate.referral_bonus}</p>
            <p className={styles.pageSubtitle}>{translate.referral_bonus_2}</p>

            <div className={styles.buttons}>
                <button className={styles.claimButton} 
                    onClick={() => utils.openTelegramLink(`https://t.me/share/url?url=https://t.me/RadiogrammBot?start=${user.telegram_id}&text="${translate.invite_text}"`)}
                >
                    <p className={styles.invite}>Invite friends</p>
                </button>
                <button className={styles.copyButton} onClick={copyToClipboard}>
                    <CopyIcon />
                </button>
            </div>
            <div className={styles.refListWrapper}>
                <ul className={styles.list}>
                    {Array.isArray(friends) && !!friends.length && friends.map((ref: any) => {
                        console.log(ref);
                        
                        return (
                            <div key={ref.telegram_id} className={styles.refItem}>
                            <div className={styles.avatar}>
                              <img src={MockImg} alt="" />
                                </div>
                                <div className={styles.info}>
                                    <div className={styles.refName}>
                                        <p className={styles.name}>{ref.first_name}</p>
                                    </div>
                                    <div className={styles.reward}>
                                        <p className={styles.rewardTopText}>BALANCE</p>
                                        <p className={styles.rewardText}>{parseInt(ref.balance)}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
};

export default RefLink;
